<template>
    <div class="doctor-page doctor-login">
        <div class="banner">
            <img src="/doctor-login.png" alt="pic" width="100%" />
        </div>
        <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            class="code"
            inline
            @submit.prevent.native="submitForm"
        >
            <div class="label">密碼</div>
            <el-form-item prop="code">
                <el-input
                    class="code-input"
                    v-model="ruleForm.code"
                    placeholder="請輸入密碼"
                ></el-input>
            </el-form-item>
        </el-form>
        <div class="confirm">
            <button-wrapper
                type="navigation"
                color="normal"
                @click="submitForm"
            >
                確定
            </button-wrapper>
        </div>
        <div class="empty"></div>
        <div class="policy-box">
            <div
                class="item reveal-information"
                @click="onOpenRevealInformationPopup"
            >
                羅氏揭露訊息
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';

export default {
    data() {
        return {
            ruleForm: {
                code: '',
            },
            rules: {
                code: [
                    {
                        required: true,
                        message: '此欄位必填',
                        trigger: 'blur',
                    },
                ],
            },
        };
    },
    methods: {
        ...mapMutations({
            setToken: 'api/setToken',
            updateRevealInformationPopupState: 'popup/updateRevealInformationPopupState',
        }),
        ...mapActions({
            apiClient: 'api/invoke',
            setState: 'doctor/setState',
        }),
        submitForm() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.onVarify();
                    return true;
                }
                return false;
            });
        },
        async onVarify() {
            const DATA = {
                id: this.$route.params.id,
                code: { code: this.ruleForm.code },
            };
            const PAYLOAD = {
                category: 'Auth',
                function: 'verifyCaseCode',
                successMsg: '驗證成功',
                data: DATA,
            };
            const RES = await this.apiClient(PAYLOAD);
            window.localStorage.setItem('doctor-token', RES.data.token);
            this.setToken(RES.data.token);
            this.setState(this.$route.params.id);
            this.$router.push({ name: 'Doctor_Intro' });
            window.history.replaceState(
                {},
                '',
                `${window.location.origin}/${this.$route.params.disease}/doctor/${this.$route.params.id}/introduction`,
            );
        },
        onOpenRevealInformationPopup() {
            this.updateRevealInformationPopupState(true);
        },
    },
};
</script>

<style lang="scss" scoped>
.doctor-login {
    .banner {
        max-width: 400px;
    }
    .code {
        margin: 50px 0 40px 0;
        display: flex;
        align-items: center;
        .label {
            margin-right: 20px;
        }
        &-input {
            width: 350px;
        }
    }
    .empty {
        margin-top: 20px;
        height: 28px;
    }
}
</style>
